import React, { useEffect, useState } from "react";
import Container from "../../layouts/Container/Container";
import { getDateNow } from "../../utils/functions";

export const Preheader = () => {
    const [showPreheader, setShowPreheader] = useState(false)
    useEffect(()=>{
        if((getDateNow() >= "2024-11-28" && getDateNow() <= "2024-12-02")){
            setShowPreheader(true)
        }
    }, [])
    console.log("EJEMPLO", showPreheader)
  return (
    <section className="preheader">
      <Container>
        {showPreheader === true ?
          <p className="contenedor">
            <b className="participa">¡Participa por un bono adicional de $200! </b>
            Cumple el Reto 1 antes del 2 de diciembre y sé parte del sorteo.
          </p>
        :
          <p className="contenedor">
            <b>¡El Reto 1 ha finalizado! </b>
            <br />
            Si lo cumpliste, ya estás participando.
            <b> Prográmate desde el 12 de diciembre para el Reto 2.</b>
          </p>
        }
      </Container>
    </section>
  );
};
