import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { connect } from 'react-redux'

// pages
import Login from "./components/pages/Login"
// import Mechanic from "./components/pages/Mechanic"
import Progress from "./components/pages/Progress"
import Awards from "./components/pages/Awards"
import Redemptions from "./components/pages/Redemptions"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
// layout
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'

// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'

import {
  gtUser,
  gtStaticContent
} from './utils/helpers'
import { getPrice, sendDataLayer } from './utils/functions'
import { doRedeem } from './utils/helpers'

import './styles/main.scss'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)

  const [awardSelected, setAwardSelected] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showSuccessRedemtion, setShowSuccessRedemtion] = useState(false)
  const [quantumName, setQuantumName] = useState('')
	const [quantumDoc, setQuantumDoc] = useState('')
  const [department, setDepartment] = useState(false)
  const [city, setCity] = useState(false)
  const [site, setSite] = useState(false)

  const home_page = '/progreso'
  const pages = window.innerWidth > 992
    ? [
        {page: 'progreso', title: 'Meta y progreso', sublevel: false, component: <Progress privatePage />},
        {page: 'premios', title: 'Bonos', sublevel: false, component: <Awards privatePage />},
        {page: 'mis-redenciones', title: 'Redenciones', sublevel: false, component: <Redemptions privatePage />},

        {page: 'mas', title: 'Otros', icon: 'arrowDown', sublevel: true, children: [
          {page: 'terminos-y-condiciones', title: 'Términos y condiciones', component: <Terms />},
          {page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', component: <Faqs />}
        ]}
      ]
    : [
        {page: 'progreso', title: 'Meta y progreso', sublevel: false, component: <Progress privatePage />},
        {page: 'premios', title: 'Bonos', sublevel: false, component: <Awards privatePage />},
        {page: 'mis-redenciones', title: 'Redenciones', sublevel: false, component: <Redemptions privatePage />},

        {page: 'mas', title: 'Otros', icon: 'hambur', iconActive: 'closeHambur', sublevel: true, children: [
          {page: 'progreso', title: 'Meta y progreso', component: <Progress privatePage />},
          {page: 'premios', title: 'Bonos', component: <Awards privatePage />},
          {page: 'mis-redenciones', title: 'Redenciones', component: <Redemptions privatePage />},
          {page: 'terminos-y-condiciones', title: 'Términos y condiciones', component: <Terms />},
          {page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', component: <Faqs />}
        ]}
      ]

  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/'
  }

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout( () => {
        localStorage.removeItem('userNotFound')
      }, 3000 )
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        console.log("Navegate to:", window.location.search)
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', '') )
        const public_children_urls = pages.find((page) => page.sublevel)

        if (public_url) {
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/'

        } else if (public_children_urls) {

          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', '') )
          if (public_child_url?.component?.props?.privatePage) navigate('/')
        }
      }
    }
  }, [searchParams, error])

  useEffect(() => {

    if (token !== '') {
      gtUser(token).then((user) => {

        if (user?.data) {
          setContext(user.data)

          if (window.location.pathname !== home_page && window.location.pathname === '/') {
            window.location.href = home_page
          }

        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/'
        }
      }).catch(() =>  navigate('/'))

    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])

  const handlerModal = () => {
    if (showConfirm) {
      sendDataLayer({datalayer: {event: 'cancel_bond',product_name: awardSelected?.name ,product_price: awardSelected?.price, index: awardSelected?.index},repeat: true})
    }
    setShowConfirm(!showConfirm)

  }

  const handlerRedemtion = async () => {
    setRedemtionLoading(true)
    let validator = true
    if (awardSelected.twoStepAuth) {
      console.log("Validar aqui si la fecha es correcta")
      validator = false;
    }

    if (awardSelected.canRedem) {
      if (validator) {
        doRedeem(token, awardSelected.price, awardSelected.id, quantumName, quantumDoc, site?.selected).then((data) => {
          setRedemtionLoading(false)
          setShowConfirm(false)
  
          if (data && data.status === 200 && data.file) {
            setShowSuccessRedemtion(true)
            sendDataLayer({datalayer: { event: "redeem_bond",product_name: awardSelected?.name,product_price: awardSelected?.price,index: awardSelected?.index}, repeat: false})

  
            const pdfBlob = fetch(data.base64).then(res => res.blob());
            pdfBlob.then(blob => {
              const pdfUrl = URL.createObjectURL(blob);
              window.open(pdfUrl, '_blank');
              
              setTimeout(() => URL.revokeObjectURL(pdfUrl), 10000);
            })

          setTimeout( () => {
            window.location.href = 'mis-redenciones'
          }, 5000 )
          } else {
            sendDataLayer({datalayer: {event: 'exhausted_bond', product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index},repeat: true})
            setShowErrorRedemtion(true)

          }
        }).catch(error => {
          console.log("ERROR ON REDENTION:", error)
        })
      } else {
        setRedemtionLoading(false)
        setShowConfirm(false)
        setShowErrorRedemtion(true)
        console.log("Validacion no completada.")
      }
    } else {
      setRedemtionLoading(false)
      setShowConfirm(false)
      setShowErrorRedemtion(true)
    }
  }

  return (
    <StateController.Provider
      value = {
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages,
          awardSelected: awardSelected,
          setAwardSelected: setAwardSelected,
          handlerRootModal: handlerModal
        }
      }
    >
      <CustomModal
        globalModal
        title="Datos incorrectos"
        desc="Verifica tu información y vuelve a intentarlo."
        cta="Aceptar"
        icon={false}
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Cerrar',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />

      <CustomModal
        show={showConfirm}
        setShowModal={setShowConfirm}
        redemtion={true}
        image={awardSelected.image}
        title={`Valor: USD${getPrice(awardSelected.price)}`}
        desc={awardSelected.name}
        twoStepAuth={awardSelected.twoStepAuth}
        location={awardSelected?.location}
        brand_id={awardSelected?.brand_id}
        quantumName={quantumName}
        quantumDoc={quantumDoc}
        setQuantumName={setQuantumName}
        setQuantumDoc={setQuantumDoc}
        department={department}
        city={city}
        site={site}
        setDepartment={setDepartment}
        setCity={setCity}
        setSite={setSite}
        token={token}
        awardSelected={awardSelected}
        primaryAction={
          {
            action: handlerRedemtion,
            buttonText: "Descargar",
            loading: redemtionLoading
          }
        }
        terms={
          {
            termsText: "Términos y condiciones",
            termsLink: "/terminos-y-condiciones"
          }
        }
      />
      <CustomModal
        globalModal
        title="Lo sentimos"
        desc={'El bono no puede ser descargado en estos momentos. Intenta de nuevo mas tarde.'}
        cta="Aceptar"        
        show={showErrorRedemtion} setShowModal={()=> {setShowErrorRedemtion(); window.location.reload()}}
      />
      <CustomModal      
        globalModal
        title="Redención correcta"
        desc={`Tu bono ha sido descargado. Podrás encontrarlo en la sección <strong>"Redenciones"</strong>.`}
        cta="Aceptar"        
        show={showSuccessRedemtion} setShowModal={setShowSuccessRedemtion}
      />

      <div className={`App ${modalOpen ? 'modalOpen' : ''} ${loggedIn ? 'loggedIn' : 'noLoggedIn'}`}>
        <Header
          headerType={1}
          logo="logo.png"
          items={pages}
          loggedIn={loggedIn}
          logOutAction={logOutAction}
        />

        <Routes>
          <Route path="/" element={<Login autologin={autologinPreloader} hash={searchParams.get('utm_web')} />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          <Route path="*" element={<NotFound loggedIn={loggedIn} />} />
        </Routes>

        <Footer
          footerType={1}
          footerCardsImg="footer_cards.png"
          secondaryFooterImg="footer_cards_2.png"
          bankFooterImg="bank-footer.png"
          links={
            [
              {
                link: "/terminos-y-condiciones",
                text: "Términos y condiciones"
              },
              {
                link: "/preguntas-frecuentes",
                text: "Preguntas frecuentes"
              }
            ]
          }
          linksText="Válido del XX de xxxxxxxx de xxxx al XX de xxxxxx de xxxx."
          content="© Copyright 2024. Todos los derechos reservados"
          copyright="© Copyright 2024"
        />

      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)